//@ts-nocheck
export async function useStoryblokStory(slug) {
    const runtimeConfig = useRuntimeConfig();
    const localeCode = useLocaleCode();
    const countryCode = useCountryCode();
    const isLiveOnly = runtimeConfig.public.STORYBLOK.IS_LIVE_ONLY === "true";

    const storyblokApi = useStoryblokApi();

    const response = await storyblokApi.get(`cdn/stories/${slug}`, {
        version: "published",
        language: localeCode.value,
        resolve_links: "url",
        resolve_relations: "global_reference.reference",
    });

    const story = response.data?.story;
    if (!story) {
        return null;
    }

    const storyContent = story.content;

    if (isLiveOnly && storyContent.is_live !== true) {
        return null;
    }

    if (countryCode.value && (!storyContent.country || !storyContent.country.includes(countryCode.value))) {
        return null;
    }

    return {
        ...story,
        rels: response.data.rels || [],
        links: response.data.links || [],
    };
}
