<template>
  <div v-if="story" class="footer">
    <Component
      :is="useComponentName(blok)"
      v-for="blok in story.content.body"
      :key="blok._uid"
      :blok="blok"
    />
  </div>
</template>

<script setup>
import {useStoryblokStory} from "@/composables/useStoryblokStory";

const story = await useStoryblokStory("checkout/footer");
</script>

<style lang="scss" scoped>
.footer {
  background: var(--body-bg-alt, $white);
  margin-top: auto;
}
</style>
