<template>
  <div class="page-content-grid-wrapper">
    <AppHeader />
    <NuxtPage class="page-content-grid" />
    <AppFooter />
  </div>
</template>

<style lang="scss">
.page-content-grid {
  background: $white;
  flex: 1;
  display: grid;
  min-height: 100%;
  position: relative;
  align-content: start;
  padding-top: calc(var(--logo-height) + 2 * var(--topbar-padding-vertical));
  
  @include min-screen('medium') {
    grid-template-rows: auto 1fr;
    grid-template-columns: 3fr 2fr;
    grid-template-areas: "steps summary" "content summary";
  }
  
  &-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
}
</style>
